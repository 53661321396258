.it-main
{
    height: 100vh;
    .it-topbar
    {
        height: 60px;
        width: 100%;
        background: #FD1010;
        .topbar-container
        {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100% !important;
            max-width: 95vw;
            padding: 0 15px 0 0;
            width: 100%;
            margin-right: auto;
            margin-left: auto;
            .topbar-menu
            {
                height: 100% !important;
                display: flex;
                align-items: center;
                gap:18px;
                .tb-menu-logo
                {
                    background-color: #ffffff;
                    border-radius: 8px;
                    padding: 5px;
                }
            }
        }
        
    }
    .it-layout
    {
        height: calc(100% - 60px);
        display: flex;
        .it-sideNav
        {
            width: 240px;
            .sideNav-container
            {
                height: 100%;
                background: #2F5497 !important;
                .rs-sidenav-body
                {
                    background: #2F5497 !important;
                    .rs-sidenav-item
                    {
                        background: #2F5497 !important;
                        color: #ffffff;
                        
                    };
                    .rs-sidenav-item:hover
                    {
                        background: #ffffff !important;
                        color: #2F5497 !important;
                    }
                }
            }
        }
    }
}
