.btn-ncr
{
    border: none !important;
}
.btn-ncr.primary
{
    background: #08369B;
}
.btn-ncr.secondary
{
    background: #F62121;
}