.login-nelcar
{
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(circle, rgb(10 60 202) 0%, #042e52 100%);
    .login-svg {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
}
.login-container
{
   // position: absolute;
   z-index: 10000;
     height: 80vh;
     width: 30vw;
     background:#ffffff;
     border-radius: 20px;
     padding: 20px 30px;
     display: flex;
     justify-content: center;
     align-items: center;
     form{
        width: 100%;
     }
     .login-img
     {
        display: flex;
        justify-content: center;
        align-items:center;
        padding-top: 30px;
     }
     .login-titulo
     {
        display: flex;
        justify-content: center;
        align-items:center;
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 20px;
     }
}

@media screen and (max-width: 767px) {
    .login-container
    {
        height: 70vh;
        width: 90vw;
    }
    .login-img
    {
        padding-top: 20px!important;
    }
    .login-titulo
    {
        padding-bottom: 10px!important;
    }
}